<template>
    <div>Dashboard JJK works!</div>
</template>
<script lang="ts">

export default {
  name: 'extensionBody',
  components: { }
}

</script>